import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';
import Icon from '../../components/Icon';
import OutboundLink from '../../components/OutboundLink';

const AlumniCenterOverview = () => (
  <Layout title="Welcome to the Alumni Center" className="alumni-center">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-blue"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../alumni-center/overview">Overview</Link>
          </li>
          <li className="breadcrumb active">Welcome to the Alumni Center</li>
        </ul>
        <h1 className="page-title">Welcome to the Alumni Center</h1>
        <Image filename="hero-alumni-overview.png" className="hero-image" />
      </div>
    </div>
    {/* The Community */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h2>The Community</h2>
            <p className="text-24">
              The 3M Young Scientist Challenge Alumni Network brings together a
              growing community of former Challenge finalists and mentors to
              connect over a unique shared experience, celebrate the
              achievements of fellow alumni, remain engaged in the 3M Young
              Scientist Challenge program, and continue innovating.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* About the Alumni Network */}
    <Section className="mb-2">
      <Container>
        <Row>
          <Column size={6}>
            <Image filename="image-alumni-center-promo-1.jpg" />
          </Column>
          <Column size={5} className="pl-1 pb-6">
            <h3 className="text-38 mb-0">About the Alumni Network</h3>
            <p className="text-24">
              The 3M Young Scientist Challenge Alumni Network is comprised of
              finalists and mentors who formerly participated in the Challenge,
              ranging from middle school to PhD candidates, and individuals
              pursuing full-time careers. Alumni collaborate in networking
              events, and cheer on the next generation of young scientists.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Alumni Spotlight */}
    <Section className="mb-2">
      <Container>
        <Row>
          <Column size={5} className="ml-6 pb-6">
            <h3 className="text-38 mb-0">Alumni Spotlight</h3>
            <h4 className="global-intro-text">
              Learn more about our Alumni and find out what they have been up to
              since participating in the Challenge.
            </h4>
            <Link
              to="/alumni-center/alumni-spotlight"
              className="cta__link no-arrow bg-gradient-blue"
            >
              Learn More <Icon name="arrowright" />
            </Link>
          </Column>
          <Column size={6} className="pl-2">
            <Image filename="image-alumni-center-promo-2.jpg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Alumni Grants Awards -- HIDDEN FOR 2024 CHALLENGE
    <Section className="mb-2">
      <Container>
        <Row>
          <Column size={6}>
            <Image filename="image-alumni-center-promo-3.jpg" />
          </Column>
          <Column size={5} className="pl-1 pb-4">
            <h3 className="text-38 mb-0">Alumni Grants Awards</h3>
            <p className="text-24">
              Scientists are always innovating! As part of the Alumni Network,
              former finalists are eligible to apply for continued support
              through the annual Grant Awards. The Grant Awards provide
              additional funding for current Alumni projects such as
              continuation of their original entry project, a new innovation, or
              promoting science in their community.
            </p>
            <OutboundLink
              to="https://webportalapp.com/sp/login/discoveryed_alumni-grant-award"
              className="cta__link no-arrow bg-gradient-blue"
            >
              Alumni Grant Portal <Icon name="arrowright" />
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section> */}
    {/* Alumni Updates */}
    <Section className="mb-2">
      <Container>
        <Row>
          <Column size={5} className="ml-6 pb-6">
            <h3 className="text-38 mb-0">Alumni Updates</h3>
            <p className="text-24">
              Stay up–to–date with all the latest updates and news stories from
              3M Young Scientist Challenge.
            </p>
            <Link
              to="/news-and-events/recent-news"
              className="cta__link no-arrow bg-gradient-blue"
            >
              Learn More <Icon name="arrowright" />
            </Link>
          </Column>
          <Column size={6} className="pl-2">
            <Image filename="image-alumni-center-promo-4.jpg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/*Contact the Alumni Network */}
    <Section className="mb-2">
      <Container>
        <Row>
          <Column size={6}>
            <Image filename="image-alumni-center-promo-5.jpg" />
          </Column>
          <Column size={5} className="pl-1 pb-6">
            <h3 className="text-38 mb-0">Contact the Alumni Network</h3>
            <p className="text-24">
              All finalists and mentors who have previously participated in the
              3M Young Scientist Challenge are eligible to join the Alumni
              Network.
            </p>
            <OutboundLink
              to="mailto:YSCAlumni@discoveryed.com"
              className="cta__link no-arrow bg-gradient-blue"
            >
              Contact Us to Join <Icon name="arrowright" />
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AlumniCenterOverview;
